import { createSlice } from "@reduxjs/toolkit";
import {
  ProjectManagementPmr,
  ProjectManagementPmrShowFlexTasks,
  ProjectManagementPmrWrapper,
} from "./prohectManagementPmrSliceInterfaces";
import { ProjectShowHideColumnsEnum } from "../../pages/project-management-pmr/shared/enums/project-show-hide-columns";
import { ShowHideColumnsMultipleDataTableModel } from "../../shared/show-hide-columns-multiple-prime-datatable/interface/ShowHideColumnsMultipleDataTableModel";
import {
  GridColumnModel,
  projectListcolumns,
} from "../../pages/project-management-pmr/data/project-grid-columns";
import { projectTaskListColumns } from "../../pages/project-management-pmr/data/project-task-grid-columns";
import { projectDetailsOrderlneListColumns } from "../../pages/project-management-pmr/data/project-details-orderline-list-grid-columns";
import { projectOrderLineColumns } from "../../pages/project-management-pmr/data/project-order-line-grid-columns";
import { projectMilestoneColumns } from "../../pages/project-management-pmr/data/project-milestone-grid-columns";
const initialState: ProjectManagementPmr = {
  showFlexTasks: { isOn: true, isFromClicked: false },
  refinerState: {
    updateForm: true,
    value: {},
  },
  globalSearchTarget: {
    projectLevel: [],
    taskLevel: [],
  },
  timeEntryUpdateTaskOrderlines: [],
  selectedIndices: {
    last: null,
    shift: null,
  },
  isRefinerSearching: false,
  showHideColumns: [
    {
      key: ProjectShowHideColumnsEnum.Project,
      title: "Project",
      fields: projectListcolumns,
      maxFrozenColumnCount: 4,
      frozenColumnCount: 1,
      hasShowAndFreezeSelection: true,
      displayFrozenColumnDropdown: false,
    },
    {
      key: ProjectShowHideColumnsEnum.Table,
      title: "Table",
      subHeader: "Task",
      fields: projectTaskListColumns,
      maxFrozenColumnCount: 4,
      frozenColumnCount: 2,
      hasShowAndFreezeSelection: true,
      displayFrozenColumnDropdown: false,
    },
    {
      key: ProjectShowHideColumnsEnum.Table,
      title: "Table",
      subHeader: "Order Line",
      fields: projectOrderLineColumns,
      maxFrozenColumnCount: 4,
      frozenColumnCount: 1,
      hasShowAndFreezeSelection: true,
      displayFrozenColumnDropdown: false,
    },
    {
      key: ProjectShowHideColumnsEnum.OrderLine,
      title: "Order Line Details",
      fields: projectDetailsOrderlneListColumns,
      maxFrozenColumnCount: 7,
      frozenColumnCount: 0,
      hasShowAndFreezeSelection: true,
      displayFrozenColumnDropdown: false,
    },
  ],
};

export const projectManagementPmrSlice = createSlice({
  name: "ProjectManagementPmr",
  initialState,
  reducers: {
    updateShowFlexTaskFullProjectView: (state, action) => {
      state.showFlexTasks = action.payload as ProjectManagementPmrShowFlexTasks;
    },
    updateShowHideFullProjectView: (state, action) => {
      state.showHideColumns = action.payload;
    },
    updateShowFlexTasks: (state, action) => {
      state.showFlexTasks = action.payload as ProjectManagementPmrShowFlexTasks;

      if (state.showFlexTasks.isOn === false) {
        const getCurrentOrderLineTableState = state.showHideColumns
            .filter(showHideKey => 
              showHideKey.key === ProjectShowHideColumnsEnum.Table && showHideKey.subHeader === "Order Line")[0];

        const currentMilestoneColumnState = getCurrentOrderLineTableState?.prevFieldState ?? projectMilestoneColumns;

        const milestoneFields = currentMilestoneColumnState.map((milestone) => {
          const getMatchingColumnFromOrderLine = getCurrentOrderLineTableState?.fields.find(
              (orderLineFields) =>
                orderLineFields.id === milestone.id &&
                orderLineFields.orderNoShowHide !== 0
            );

          if (getMatchingColumnFromOrderLine) {
            return {
              ...milestone,
              isShown: getMatchingColumnFromOrderLine.isShown,
              isFrozen: getMatchingColumnFromOrderLine.isFrozen,
              width: getMatchingColumnFromOrderLine.width,
            };
          }
          return milestone;
        });

        state.showHideColumns = state.showHideColumns.map((showHideKey) => {
          if (
            showHideKey.key === ProjectShowHideColumnsEnum.Table &&
            showHideKey.subHeader === "Order Line"
          ) {
            return {
              ...showHideKey,
              fields: milestoneFields,
              prevFieldState: getCurrentOrderLineTableState?.fields.map(saveOrderLineState => saveOrderLineState),
              subHeader: "Milestone",
            };
          }
          return showHideKey;
        });
      }

      if (state.showFlexTasks.isOn === true) {
        const getCurrentMilestoneTableState = state.showHideColumns
        .filter(showHideKey => 
          showHideKey.key === ProjectShowHideColumnsEnum.Table && showHideKey.subHeader === "Milestone")[0];

        const currentOrderLineColumnState = getCurrentMilestoneTableState?.prevFieldState ?? projectOrderLineColumns;

          const orderLineFields = currentOrderLineColumnState.map((orderLine) => {
            const getMatchingColumnFromOrderLine = getCurrentMilestoneTableState?.fields.find(
                (milestoneFields) =>
                  milestoneFields.id === orderLine.id &&
                  milestoneFields.orderNoShowHide !== 0
              );
  
            if (getMatchingColumnFromOrderLine) {
              return {
                ...orderLine,
                isShown: getMatchingColumnFromOrderLine.isShown,
                isFrozen: getMatchingColumnFromOrderLine.isFrozen,
                width: getMatchingColumnFromOrderLine.width,
              };
            }
            return orderLine;
          });
          state.showHideColumns = state.showHideColumns.map((showHideKey) => {
            if (
              showHideKey.key === ProjectShowHideColumnsEnum.Table &&
              showHideKey.subHeader === "Milestone"
            ) {
              return {
                ...showHideKey,
                fields: orderLineFields,
                prevFieldState: getCurrentMilestoneTableState?.fields.map(saveMilestoneState => saveMilestoneState),
                subHeader: "Order Line",
              };
            }
            return showHideKey;
          });
      }
    },
    updateRefinerState: (state, action) => {
      if (!action.payload.value) return;
      state.refinerState = action.payload;
    },
    updateGlobalSearchTarget: (state, action) => {
      state.globalSearchTarget = {
        ...state.globalSearchTarget,
        ...action.payload,
      };
    },
    updateTimeEntryUpdateTaskOrderlines: (state, action) => {
      state.timeEntryUpdateTaskOrderlines = action.payload;
    },
    updateSelectedIndices: (state, action) => {
      state.selectedIndices = action.payload;
    },
    updateIsRefinerLoading: (state, action) => {
      state.isRefinerSearching = action.payload;
    },
    updateShowHideColumn: (state, action) => {
      const payload = action.payload as Array<{
        key: ProjectShowHideColumnsEnum;
        fields: GridColumnModel[];
        prevFieldState?: GridColumnModel[];
        subHeader: string;
        frozenCount: number;
      }>;

      payload?.forEach((item) => {
        state.showHideColumns = state.showHideColumns.map(
          (showHideColumnKey) => {
            if (showHideColumnKey.key === item.key) {
              showHideColumnKey.fields = item.subHeader
                ? item.subHeader === showHideColumnKey.subHeader
                  ? [...item.fields]
                  : showHideColumnKey.fields
                : [...item.fields];
              showHideColumnKey.frozenColumnCount = item.frozenCount;
              showHideColumnKey.prevFieldState = item.prevFieldState;

              return showHideColumnKey;
            }
            return showHideColumnKey;
          }
        );
      });
    },
    updateShowHideColumnByTable: (state, action) => {
      const payload = action.payload;

      state.showHideColumns = state.showHideColumns.map((showHideColumnKey) => {
        if (showHideColumnKey.key === payload.key) {
          const updateFieldValues = payload.subHeader
            ? payload.subHeader === showHideColumnKey.subHeader
              ? true
              : false
            : true;

          showHideColumnKey.fields = updateFieldValues
            ? [...payload.newColumns]
            : showHideColumnKey.fields;
            
          showHideColumnKey.frozenColumnCount = updateFieldValues
            ? payload.frozenCount
            : showHideColumnKey.frozenColumnCount;
          return {...showHideColumnKey};
        }

        return showHideColumnKey;
      });
    },
    updateShowHideColumnObject: (state, action) => {
      state.showHideColumns = action.payload;
    },
  },
});

export const {
  updateShowFlexTasks,
  updateRefinerState,
  updateGlobalSearchTarget,
  updateTimeEntryUpdateTaskOrderlines,
  updateSelectedIndices,
  updateIsRefinerLoading,
  updateShowHideColumn,
  updateShowHideColumnByTable,
  updateShowHideColumnObject,
  updateShowFlexTaskFullProjectView,
  updateShowHideFullProjectView
} = projectManagementPmrSlice.actions;

export const selectShowFlexTasks = (state: ProjectManagementPmrWrapper) =>
  state.projectManagementPmr.showFlexTasks;
export const selectRefinerState = (state: ProjectManagementPmrWrapper) =>
  state.projectManagementPmr.refinerState;
export const selectGlobalSearchTarget = (state: ProjectManagementPmrWrapper) =>
  state.projectManagementPmr.globalSearchTarget;
export const selectTimeEntryUpdateTaskOrderlines = (
  state: ProjectManagementPmrWrapper
) => state.projectManagementPmr.timeEntryUpdateTaskOrderlines;
export const selectSelectedIndices = (state: ProjectManagementPmrWrapper) =>
  state.projectManagementPmr.selectedIndices;
export const selectIsRefinerSearching = (state: ProjectManagementPmrWrapper) =>
  state.projectManagementPmr.isRefinerSearching;
export const generateGlobalSearchFilters = (
  state: ProjectManagementPmrWrapper,
  e2eProjectId: string
) => {
  const refinerState = state.projectManagementPmr.refinerState;
  const globalSearchTarget = state.projectManagementPmr.globalSearchTarget;

  const searchString = (refinerState.value?.searchString ?? "")
    .toLowerCase()
    .trim();

  const orderLinesRefiner = refinerState.value?.orderLines;
  const orderLineFilters: { label: string; value?: any }[] =
    orderLinesRefiner && orderLinesRefiner?.orderLineDescriptions
      ? orderLinesRefiner?.orderLineDescriptions
      : [];

  const flexProjectNumbersFilters: { label: string; value?: any }[] =
    orderLinesRefiner && orderLinesRefiner?.flexProjectNumbers
      ? orderLinesRefiner?.flexProjectNumbers
      : [];

  const projectLevelHasResult = globalSearchTarget.projectLevel.some(
    (projectId) => projectId === e2eProjectId
  );

  if (flexProjectNumbersFilters.length > 0) {
    orderLineFilters.push(...flexProjectNumbersFilters);
  }

  if (searchString !== "" && !projectLevelHasResult) {
    orderLineFilters.push({
      label: searchString,
      value: searchString,
    });
  }

  return orderLineFilters;
};

export const selectShowHideColumnProjects = (
  state: ProjectManagementPmrWrapper
) => state.projectManagementPmr.showHideColumns;

export const selectShowHideColumnProjectsProject = (
  state: ProjectManagementPmrWrapper
) =>
  state.projectManagementPmr.showHideColumns.find(
    (column) => column.key === ProjectShowHideColumnsEnum.Project
  );

export const selectShowHideColumnProjectsTableTask = (
  state: ProjectManagementPmrWrapper
) =>
  state.projectManagementPmr.showHideColumns.find(
    (column) =>
      column.key === ProjectShowHideColumnsEnum.Table &&
      column.subHeader === "Task"
  );

export const selectShowHideColumnProjectsTableOrderLine = (
  state: ProjectManagementPmrWrapper
) =>
  state.projectManagementPmr.showHideColumns.find(
    (column) =>
      column.key === ProjectShowHideColumnsEnum.Table &&
      column.subHeader === "Order Line"
  );

export const selectShowHideColumnProjectsTableMilestone = (
  state: ProjectManagementPmrWrapper
) =>
  state.projectManagementPmr.showHideColumns.find(
    (column) =>
      column.key === ProjectShowHideColumnsEnum.Table &&
      column.subHeader === "Milestone"
  );

export const selectShowHideColumnProjectsOrderLine = (
  state: ProjectManagementPmrWrapper
) =>
  state.projectManagementPmr.showHideColumns.find(
    (column) => column.key === ProjectShowHideColumnsEnum.OrderLine
  );

export default projectManagementPmrSlice.reducer;
